import Product from "../types/product";

class ProductsService {
  async getProducts(): Promise<Product[]> {
    const res = await fetch("/data/products.json", {
      method: "GET",
    });

    const products = await res.json();

    return products;
  }

  async getProductsChunks(): Promise<[Product, Product][]> {
    const products = await this.getProducts();

    const chunkSize = 2;

    const productChunks: any[] = [[]];
    let currentIdx = 0;

    products.forEach((product) => {
      const currentArray = productChunks[currentIdx];

      if (currentArray.length < chunkSize) {
        currentArray.push(product);
        return;
      }

      productChunks.push([product]);
      currentIdx++;
    });

    return productChunks;
  }

  async getProduct(productId: number | string): Promise<Product | null> {
    const products = await this.getProducts();

    const product = products.filter((product) => Number(product.id) === Number(productId))[0] ?? null;

    return product;
  }
}

export default new ProductsService();
