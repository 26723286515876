import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
`;

const HeaderBurgerBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const HeaderBurgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 40px;
  padding-bottom: 126px;
  z-index: 9999;

  @media (max-width: 420px) {
    padding: 40px 20px 126px 20px;
  }

  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background-color: white;
    opacity: 0.95;
    width: 100%;
    height: 444px;
    z-index: -1;
  }
`;

const HeaderBurgerMenuCloseBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 400px;
`;

const HeaderBurgerMenuCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 22px;
  width: 22px;
  background-color: transparent;
  cursor: pointer;
`;

const HeaderBurgerMenuCloseLine = styled.div`
  width: 27px;
  height: 3.5px;
  background-color: #015b65;
  &:first-of-type {
    position: absolute;
    transform: rotate(45deg);
  }
  &:last-of-type {
    position: absolute;
    transform: rotate(-45deg);
  }
  @media (max-width: 420px) {
    width: 13.5px;
    height: 1.75px;
  }
`;

interface IHeaderBurgerMenuButton {
  backgroundColor: "#015b65" | "#ff7656";
}

const HeaderBurgerMenuButton = styled.button<IHeaderBurgerMenuButton>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-top: 24px;
  height: 32px;
  width: 100%;
  max-width: 400px;
  color: white;
  letter-spacing: 0.2px;
  font-size: ${pxIntoRem(14)};
  font-family: "Roboto", sans-serif;
  cursor: pointer;

  &:first-of-type {
    margin-top: 48px;
    @media (max-width: 420px) {
      margin-top: 24px;
    }
  }

  @media (max-width: 420px) {
    height: 27px;
  }
`;

const HeaderBurgerButton = styled.button`
  display: block;
  cursor: pointer;
  background-color: transparent;
`;

const HeaderBurgerLine = styled.div`
  width: 27px;
  height: 3.5px;
  background-color: #015b65;
  margin-top: 4px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const HeaderLogoBlock = styled.div`
  width: 100%;
  margin-top: 9px;
`;

const HeaderLogo = styled.img`
  max-width: 345px;
  cursor: pointer;
  @media (max-width: 450px) {
    max-width: 320px;
  }
  @media (max-width: 420px) {
    max-width: 270px;
  }
  @media (max-width: 400px) {
    max-width: 240px;
  }
`;

export {
  HeaderBlock,
  HeaderBurgerBlock,
  HeaderBurgerMenu,
  HeaderBurgerMenuButton,
  HeaderBurgerButton,
  HeaderBurgerLine,
  HeaderLogoBlock,
  HeaderLogo,
  HeaderBurgerMenuCloseBlock,
  HeaderBurgerMenuCloseButton,
  HeaderBurgerMenuCloseLine,
};
