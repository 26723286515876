import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const OrderContactBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
`;

const OrderContactInput = styled.input`
  height: 32px;
  width: 100%;
  max-width: 185px;
  background-color: white;
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: black;
  padding: 0px 13px;
  margin-right: 5px;

  &:disabled {
    background-color: #a9a9a9;
    cursor: not-allowed;
  }

  @media (max-width: 420px) {
    height: 27px;
    padding: 0px 6.5px;
  }
`;

const OrderContactTakeTelegram = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
`;

const OrderContactTakeTelegramText = styled.span`
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: white;
  line-height: ${pxIntoRem(14)};
  letter-spacing: -0.11px;
  margin-right: 5px;
`;

const OrderContactTakeTelegramCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: black;
  width: 22px;
  height: 22px;
  background-color: white;
  @media (max-width: 420px) {
    height: 17px;
    width: 17px;
  }
`;

export { OrderContactBlock, OrderContactInput, OrderContactTakeTelegram, OrderContactTakeTelegramCheckbox, OrderContactTakeTelegramText };
