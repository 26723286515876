import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
  * {
    margin: 0;
    padding: 0;
    border: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    height: auto;
    background-color: white;
    font-size: 16px;
    @media(max-width: 500px) {
      font-size: 14.5px;
    }
    @media(max-width: 450px) {
      font-size: 13.5px;
    }
    @media(max-width: 430px) {
      font-size: 13px;
    }
    @media(max-width: 420px) {
      font-size: 12px;
    }
    @media(max-width: 370px) {
      font-size: 10.5px;
    }
    @media(max-width: 350px) {
      font-size: 10px;
    }
    @media(max-width: 330px) {
      font-size: 9.5px;
    }
    @media(max-width: 320px) {
      font-size: 9px;
    }
  }
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Router>
    <GlobalStyle />
    <App />
  </Router>
);
