import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Default from "../../layouts/Default/Default";
import productsService from "../../services/productsService";
import Product from "../../types/product";
import {
  IndexBlock,
  IndexTitleBlock,
  IndexTitle,
  IndexContent,
  IndexContentText,
  IndexContentEarn,
  IndexContentCards,
  IndexContentCardsTitle,
  IndexContentCardsRow,
  IndexContentCard,
  IndexContentCardTitle,
  IndexContentCardImageBlock,
  IndexContentCardButton,
  IndexContentCustom,
  IndexContentCustomText,
  IndexContentCustomButton,
  IndexContentCardImage,
} from "./IndexStyles";

function Index() {
  const navigate = useNavigate();
  const [productsChunks, setProductsChunks] = useState<[Product, Product][]>([]);

  useEffect(() => {
    productsService.getProductsChunks().then((productsChunks) => {
      setProductsChunks(productsChunks);
    });
  }, []);

  function redirectToProduct(productId: number) {
    navigate(`/products/${productId}`);
  }

  function redirectToIntermediary() {
    navigate(`/order/intermediary`);
  }

  function redirectToCustom() {
    navigate(`/order/custom`);
  }

  return (
    <Default>
      <IndexBlock>
        <IndexTitleBlock>
          <IndexTitle>ТЕЛЕГРАМ ПРИЛОЖЕНИЕ ЭТО СОВЕРШЕННО НОВЫЙ СПОСОБ ПРОДАЖ!</IndexTitle>
        </IndexTitleBlock>
        <IndexContent>
          <IndexContentText>
            Создать свой телеграмм канал и продвинуть его ПРОСТО И ДЁШЕВО! Наши TgApps приложения позволят продавать товары, брать деньги за услуги, собирать донаты и лиды в один
            клик, прямо в телеграмм канале. Внимание это не боты!
          </IndexContentText>
          <IndexContentEarn onClick={() => redirectToIntermediary()}>Совместный заработок и партнёрство</IndexContentEarn>
          <IndexContentCards>
            <IndexContentCardsTitle>ГОТОВЫЕ ПРИЛОЖЕНИЯ</IndexContentCardsTitle>
            {productsChunks.map((productsChunk, idx) => {
              return (
                <IndexContentCardsRow key={idx}>
                  {productsChunk.map((product) => {
                    return (
                      <IndexContentCard key={product.id}>
                        <IndexContentCardTitle>
                          {product.title} <span>{product.name}</span>
                        </IndexContentCardTitle>
                        <IndexContentCardImageBlock>
                          <IndexContentCardImage alt="product-image" src={product.image} />
                        </IndexContentCardImageBlock>
                        <IndexContentCardButton onClick={() => redirectToProduct(product.id)}>Просчитать опции</IndexContentCardButton>
                      </IndexContentCard>
                    );
                  })}
                </IndexContentCardsRow>
              );
            })}
          </IndexContentCards>
        </IndexContent>
        <IndexContentCustom>
          <IndexContentCustomText>Закажите TgApps приложение «С нуля». Функции, дизайн и логика работы будет разработана сепциально для вас.</IndexContentCustomText>
          <IndexContentCustomButton onClick={() => redirectToCustom()}>Заказать разработку приложения «с нуля»</IndexContentCustomButton>
        </IndexContentCustom>
      </IndexBlock>
    </Default>
  );
}

export default Index;
