import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const OrderNameBlock = styled.input`
  height: 32px;
  width: 100%;
  background-color: white;
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: black;
  padding: 0px 13px;
  margin-top: 14px;
  @media (max-width: 420px) {
    height: 27px;
    padding: 0px 6.5px;
    margin-top: 7px;
  }
  
  &:first-child {
    margin-top: 0px;
  }
`;

export { OrderNameBlock };
