import { FC } from "react";
import { OrderNameBlock } from "./OrderNameStyles";

interface IOrderName {
  value: string;
  onChange(value: string): any;
}

const OrderName: FC<IOrderName> = ({ value, onChange }) => {
  return <OrderNameBlock placeholder="Представьтесь" value={value} onChange={(e) => onChange(e.currentTarget.value)} />;
};

export default OrderName;
