import OrdersInfo from "../types/ordersInfo";

class OrderInfoService {
  async getOrdersInfo(): Promise<OrdersInfo> {
    const res = await fetch("/data/ordersInfo.json");

    const ordersInfo = await res.json();

    return ordersInfo;
  }

  async getIntermediaryOrderInfo() {
    const ordersInfo = await this.getOrdersInfo();
    return ordersInfo.intermediary;
  }

  async getCustomOrderInfo() {
    const ordersInfo = await this.getOrdersInfo();
    return ordersInfo.custom;
  }

  async getConsultOrderInfo() {
    const ordersInfo = await this.getOrdersInfo();
    return ordersInfo.consult;
  }
}

export default new OrderInfoService();
