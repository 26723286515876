import React, { useEffect, useState } from "react";
import { OrderMessage, OrderContact, OrderName } from "../../components/Order";
import Order from "../../layouts/Order/Order";
import orderInfoService from "../../services/orderInfoService";
import { OrderInfo } from "../../types/ordersInfo";
import { WebAppEvent } from "../../types/webapp";
import WebApp from "../../utils/telegram/webapp";
import { OrderInputs, OrderTheme, OrderCheckboxes, OrderCheckbox, OrderCheckboxText, OrderCheckboxBox } from "./OrderCustomStyles";

function OrderCustom() {
  const [orderInfo, setOrderInfo] = useState<OrderInfo>({
    title: "",
    text: "",
  });

  const [theme, setTheme] = useState("");
  const [fullname, setFullname] = useState("");
  const [contact, setContact] = useState("");
  const [useTelegram, setUseTelegram] = useState(false);
  const [message, setMessage] = useState("");
  const [knowWhatNeeded, setKnowWhatNeeded] = useState(false);
  const [haveTechnicalTask, setHaveTechnicalTask] = useState(false);
  const [haveDesign, setHaveDesign] = useState(false);
  const [haveLogic, setHaveLogic] = useState(false);

  useEffect(() => {
    orderInfoService.getCustomOrderInfo().then((orderInfo) => {
      setOrderInfo(orderInfo);
    });
  }, []);

  function onButtonClick() {
    WebApp.sendJsonData({
      eventType: WebAppEvent.CustomOrder,
      data: {
        theme,
        fullname,
        contact,
        useTelegram,
        message,
        knowWhatNeeded,
        haveTechnicalTask,
        haveDesign,
        haveLogic,
      },
    });
  }

  return (
    <Order title={orderInfo.title} text={orderInfo.text} onButtonClick={onButtonClick} isButtonDisabled={!fullname || !contact || !message || !theme}>
      <OrderInputs>
        <OrderTheme placeholder="На какую тему проект" value={theme} onChange={(e) => setTheme(e.currentTarget.value)} />
        <OrderCheckboxes>
          <OrderCheckbox onClick={() => setKnowWhatNeeded((prev) => !prev)}>
            <OrderCheckboxText>Знаю что надо</OrderCheckboxText>
            <OrderCheckboxBox>{knowWhatNeeded && <>&#10003;</>}</OrderCheckboxBox>
          </OrderCheckbox>
          <OrderCheckbox onClick={() => setHaveTechnicalTask((prev) => !prev)}>
            <OrderCheckboxText>Есть ТЗ</OrderCheckboxText>
            <OrderCheckboxBox>{haveTechnicalTask && <>&#10003;</>}</OrderCheckboxBox>
          </OrderCheckbox>
          <OrderCheckbox onClick={() => setHaveDesign((prev) => !prev)}>
            <OrderCheckboxText>Есть макет</OrderCheckboxText>
            <OrderCheckboxBox>{haveDesign && <>&#10003;</>}</OrderCheckboxBox>
          </OrderCheckbox>
          <OrderCheckbox onClick={() => setHaveLogic((prev) => !prev)}>
            <OrderCheckboxText>Есть логика</OrderCheckboxText>
            <OrderCheckboxBox>{haveLogic && <>&#10003;</>}</OrderCheckboxBox>
          </OrderCheckbox>
        </OrderCheckboxes>
        <OrderName value={fullname} onChange={setFullname} />
        <OrderContact value={contact} onChange={setContact} useTelegram={useTelegram} onUseTelegramChange={setUseTelegram} />
        <OrderMessage value={message} onChange={setMessage} />
      </OrderInputs>
    </Order>
  );
}

export default OrderCustom;
