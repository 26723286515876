import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const OrderInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 27px 0px 0px 0px;
  @media (max-width: 420px) {
    padding: 12px 0px 0px 0px;
  }
`;


export { OrderInputs };
