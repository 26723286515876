import React, { useEffect, useState } from "react";
import { OrderContact, OrderMessage, OrderName } from "../../components/Order";
import Order from "../../layouts/Order/Order";
import orderInfoService from "../../services/orderInfoService";
import { OrderInfo } from "../../types/ordersInfo";
import { WebAppEvent } from "../../types/webapp";
import WebApp from "../../utils/telegram/webapp";
import { OrderInputs } from "./OrderConsultStyles";

function OrderConsult() {
  const [orderInfo, setOrderInfo] = useState<OrderInfo>({
    title: "",
    text: "",
  });

  const [fullname, setFullname] = useState("");
  const [contact, setContact] = useState("");
  const [useTelegram, setUseTelegram] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    orderInfoService.getConsultOrderInfo().then((orderInfo) => {
      setOrderInfo(orderInfo);
    });
  }, []);

  function onButtonClick() {
    WebApp.sendJsonData({
      eventType: WebAppEvent.ConsultOrder,
      data: {
        fullname,
        contact,
        useTelegram,
        message,
      },
    });
  }

  return (
    <Order title={orderInfo.title} text={orderInfo.text} onButtonClick={onButtonClick} isButtonDisabled={!fullname || !contact || !message}>
      <OrderInputs>
        <OrderName value={fullname} onChange={setFullname} />
        <OrderContact value={contact} onChange={setContact} useTelegram={useTelegram} onUseTelegramChange={setUseTelegram} />
        <OrderMessage value={message} onChange={setMessage} />
      </OrderInputs>
    </Order>
  );
}

export default OrderConsult;
