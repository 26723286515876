import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const IndexBlock = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding-bottom: 15px;
`;

const IndexTitleBlock = styled.div`
  width: 100%;
  margin-top: 22px;
`;

const IndexTitle = styled.h1`
  font-size: ${pxIntoRem(18)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: ${pxIntoRem(22)};
  color: #ff7656;
`;

const IndexContent = styled.div`
  background-color: #1b5b65;
  opacity: 0.95;
  margin-top: 15px;
  padding: 23px 32px 25px 32px;
  @media (max-width: 490px) {
    padding: 23px 23px 25px 23px;
  }
`;

const IndexContentText = styled.p`
  font-size: ${pxIntoRem(13.5)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: white;
  letter-spacing: -0.05px;
  line-height: ${pxIntoRem(21)};
`;

const IndexContentEarn = styled.button`
  width: 100%;
  height: 30px;
  margin-top: 21px;
  background-color: #ff7656;
  color: white;
  letter-spacing: -0.2px;
  font-size: ${pxIntoRem(11)};
  cursor: pointer;
  @media (max-width: 420px) {
    height: 25px;
    margin-top: 10.5px;
  }
`;

const IndexContentCards = styled.div`
  display: flex;
  flex-direction: column;
`;

const IndexContentCardsTitle = styled.h2`
  font-size: ${pxIntoRem(14)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-top: 20px;
  color: #ffb3a1;
  letter-spacing: -0.9px;
  @media (max-width: 420px) {
    margin-top: 10px;
  }
`;

const IndexContentCardsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  &:first-of-type {
    margin-top: 25px;
  }

  @media (max-width: 420px) {
    margin-top: 9px;
    &:first-of-type {
      margin-top: 12.5px;
    }
  }
`;

const IndexContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 176px;
  flex-grow: 1;
  @media (max-width: 450px) {
    max-width: 166px;
  }
  @media (max-width: 435px) {
    max-width: 161px;
  }
  @media (max-width: 420px) {
    max-width: 156px;
  }
  @media (max-width: 400px) {
    max-width: 146px;
  }
  @media (max-width: 370px) {
    max-width: 136px;
  }
  @media (max-width: 360px) {
    max-width: 126px;
  }
  @media (max-width: 340px) {
    max-width: 121px;
  }
  @media (max-width: 320px) {
    max-width: 110px;
  }
`;

const IndexContentCardTitle = styled.h3`
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: white;
  letter-spacing: 0.1px;
  span {
    color: #ffb3a1;
  }
`;

const IndexContentCardImageBlock = styled.div`
  width: 100%;
  height: 175px;
  background-color: white;
  margin-top: 9px;

  @media (max-width: 400px) {
    height: 150px;
  }

  @media (max-width: 350px) {
    height: 135px;
  }

  @media (max-width: 335px) {
    height: 120px;
  }
  @media (max-width: 320px) {
    height: 115px;
  }
`;

const IndexContentCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IndexContentCardButton = styled.button`
  margin-top: 5px;
  height: 30px;
  width: 100%;
  background-color: #ff7656;
  font-size: ${pxIntoRem(12)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: white;
  cursor: pointer;
  @media (max-width: 420px) {
    height: 27px;
  }
`;

const IndexContentCustom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  opacity: 0.95;
  padding: 9px 25px 18px 25px;
  @media (max-width: 490px) {
    padding: 9px 23px 18px 23px;
  }
`;

const IndexContentCustomText = styled.p`
  font-size: ${pxIntoRem(14)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #6e878f;
  letter-spacing: -0.1px;
  line-height: ${pxIntoRem(21)};
`;

const IndexContentCustomButton = styled.button`
  height: 32px;
  width: 100%;
  background-color: #ff7656;
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.1px;
  margin-top: 8px;
  color: white;
  cursor: pointer;
  @media (max-width: 420px) {
    height: 27px;
  }
`;

export {
  IndexBlock,
  IndexTitleBlock,
  IndexTitle,
  IndexContent,
  IndexContentText,
  IndexContentEarn,
  IndexContentCards,
  IndexContentCardsTitle,
  IndexContentCardsRow,
  IndexContentCard,
  IndexContentCardTitle,
  IndexContentCardImageBlock,
  IndexContentCardImage,
  IndexContentCardButton,
  IndexContentCustom,
  IndexContentCustomText,
  IndexContentCustomButton,
};
