function pxIntoRem(px: number) {
  const body = document.querySelector("body");
  if (body) {
    const styles = window.getComputedStyle(body);
    const globalPix = Number(styles.fontSize.slice(0, -2));
    return (px / globalPix).toFixed(3) + "rem";
  }
}

export default pxIntoRem;
