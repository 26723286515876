import { FC } from "react";
import { OrderContactBlock, OrderContactInput, OrderContactTakeTelegram, OrderContactTakeTelegramCheckbox, OrderContactTakeTelegramText } from "./OrderContactStyles";

interface IOrderContact {
  value: string;
  onChange(value: string): any;
  useTelegram: boolean;
  onUseTelegramChange(value: boolean): any;
}

const OrderContact: FC<IOrderContact> = ({ value, onChange, useTelegram, onUseTelegramChange }) => {
  return (
    <OrderContactBlock>
      <OrderContactInput placeholder="Любой контакт для связи" value={value} onChange={(e) => onChange(e.currentTarget.value)} disabled={useTelegram} />
      <OrderContactTakeTelegram onClick={() => onUseTelegramChange(!useTelegram)}>
        <OrderContactTakeTelegramText>Возьмите Telegram-контакт</OrderContactTakeTelegramText>
        <OrderContactTakeTelegramCheckbox>{useTelegram && <>&#10003;</>}</OrderContactTakeTelegramCheckbox>
      </OrderContactTakeTelegram>
    </OrderContactBlock>
  );
};

export default OrderContact;
