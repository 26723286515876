import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const OrderInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 27px 0px 0px 0px;
  @media (max-width: 420px) {
    padding: 12px 0px 0px 0px;
  }
`;

// const OrderName = styled.input`
//   height: 32px;
//   width: 100%;
//   background-color: white;
//   font-size: ${pxIntoRem(11)};
//   font-family: "Roboto", sans-serif;
//   font-weight: 500;
//   letter-spacing: -0.1px;
//   color: black;
//   padding: 0px 13px;
//   @media (max-width: 420px) {
//     height: 27px;
//     padding: 0px 6.5px;
//   }
// `;

export { OrderInputs };
