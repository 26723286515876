import React, { useEffect } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import Index from "./pages/Index/Index";
import OrderConsult from "./pages/OrderConsult/OrderConsult";
import OrderCustom from "./pages/OrderCustom/OrderCustom";
import OrderIntermediary from "./pages/OrderIntermediary/OrderIntermediary";
import Product from "./pages/Product/Product";
import WebApp from "./utils/telegram/webapp";

const AppBlock = styled.div`
  width: 100%;
  height: 100vh;
  background-color: white;
`;

function App() {
  useEffect(() => {
    WebApp.expand();
  }, []);

  return (
    <AppBlock>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/products/:id" element={<Product />} />
        <Route path="/order/intermediary" element={<OrderIntermediary />} />
        <Route path="/order/custom" element={<OrderCustom />} />
        <Route path="/order/consult" element={<OrderConsult />} />
      </Routes>
    </AppBlock>
  );
}

export default App;
