import styled from "styled-components";

const ContainerBlock = styled.div`
  max-width: 504px;
  min-height: 100%;
  padding: 0px 25px;
  background-image: url("/images/background.png");
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  @media (max-width: 420px) {
    padding: 0px 15px;
  }
`;

export { ContainerBlock };
