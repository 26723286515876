import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Default from "../../layouts/Default/Default";
import productsService from "../../services/productsService";
import ProductType, { AdditionalOption } from "../../types/product";
import { WebAppEvent } from "../../types/webapp";
import BackButton from "../../utils/telegram/backButton";
import WebApp from "../../utils/telegram/webapp";
import {
  ProductAddOption,
  ProductAddOptionCheckbox,
  ProductAddOptions,
  ProductAddOptionsList,
  ProductAddOptionsTitle,
  ProductAddOptionText,
  ProductBlock,
  ProductImage,
  ProductImageBlock,
  ProductInfo,
  ProductInfoDescription,
  ProductInfoTitle,
  ProductOption,
  ProductOptions,
  ProductOptionsList,
  ProductOptionsTitle,
  ProductOrderButton,
  ProductPrice,
  ProductPriceNet,
  ProductPriceNotNet,
  ProductTotalPrice,
} from "./ProductStyles";

function Product() {
  const { id: productId } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState<ProductType | null>(null);
  const [chosenOptions, setChosenOptions] = useState<AdditionalOption[]>([]);

  useEffect(() => {
    if (!productId) {
      navigate(-1);
      return;
    }

    productsService.getProduct(productId).then((product) => {
      if (product) {
        setProduct(product);
        return;
      }

      navigate(-1);
    });

    BackButton.onClick(() => {
      navigate(-1);
    });
    BackButton.show();

    return () => {
      BackButton.offClick();
      BackButton.hide();
    };
  }, []);

  const totalPrice = useMemo(() => {
    if (!product) {
      return 0;
    }

    const productPrice = product.price;
    const optionsPrice = chosenOptions.reduce((prev, option) => prev + option.price, 0);

    const totalPrice = productPrice + optionsPrice;

    return totalPrice;
  }, [chosenOptions.length, product]);

  function changeOption(option: AdditionalOption) {
    const optionsIds = chosenOptions.map((option) => option.id);

    const isIncluded = optionsIds.includes(option.id);
    if (isIncluded) {
      setChosenOptions((prev) => prev.filter((chosenOption) => chosenOption.id !== option.id));
      return;
    }

    setChosenOptions((prev) => [...prev, option]);
  }

  function isChecked(option: AdditionalOption) {
    const optionsIds = chosenOptions.map((option) => option.id);
    const isIncluded = optionsIds.includes(option.id);
    return isIncluded;
  }

  function orderProduct() {
    if (!product) {
      return;
    }

    WebApp.sendJsonData({
      eventType: WebAppEvent.ProductOrder,
      data: {
        product: product.name,
        price: product.price,
        options: chosenOptions,
      },
    });
  }

  return (
    <Default>
      <ProductBlock>
        <ProductPrice>
          <ProductPriceNet>
            Цена без опций: <span>{product?.price ?? 0}₽</span>
          </ProductPriceNet>
          <ProductPriceNotNet>
            Цена с выбранными опциями: <span>{totalPrice}₽</span>
          </ProductPriceNotNet>
        </ProductPrice>
        <ProductInfo>
          <ProductInfoTitle>
            {product?.title} <span>{product?.name}</span>
          </ProductInfoTitle>
          <ProductInfoDescription>{product?.description}</ProductInfoDescription>
        </ProductInfo>
        <ProductImageBlock>
          <ProductImage alt="product-image" src={product?.image} />
          <ProductOptions>
            <ProductOptionsTitle>Эти опции входят в пакет:</ProductOptionsTitle>
            <ProductOptionsList>
              {product?.options.map((option) => {
                return <ProductOption key={option.id}>{option.text}</ProductOption>;
              })}
            </ProductOptionsList>
          </ProductOptions>
        </ProductImageBlock>
        <ProductAddOptions>
          <ProductAddOptionsTitle>Выберите опции к приложению</ProductAddOptionsTitle>
          <ProductAddOptionsList>
            {product?.additionalOptions.map((option) => {
              return (
                <ProductAddOption key={option.id} onClick={() => changeOption(option)}>
                  <ProductAddOptionCheckbox isChecked={isChecked(option)}>{isChecked(option) && <>&#10003;</>}</ProductAddOptionCheckbox>
                  <ProductAddOptionText>{option.text}</ProductAddOptionText>
                </ProductAddOption>
              );
            })}
          </ProductAddOptionsList>
          <ProductTotalPrice>
            Цена с выбранными опциями: <span>{totalPrice}₽</span>
          </ProductTotalPrice>
          <ProductOrderButton onClick={() => orderProduct()}>Заказать приложение вместе с опциями</ProductOrderButton>
        </ProductAddOptions>
      </ProductBlock>
    </Default>
  );
}

export default Product;
