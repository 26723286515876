import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const OrderInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0px 0px 0px;
  @media (max-width: 420px) {
    padding: 12px 0px 0px 0px;
  }
`;

const OrderTheme = styled.input`
  height: 32px;
  width: 100%;
  background-color: white;
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: black;
  padding: 0px 13px;
  @media (max-width: 420px) {
    height: 27px;
    padding: 0px 6.5px;
  }
`;

const OrderCheckboxes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 14px;
  @media (max-width: 420px) {
    margin-top: 7px;
  }
`;

const OrderCheckbox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const OrderCheckboxText = styled.span`
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: white;
  line-height: ${pxIntoRem(14)};
  letter-spacing: -0.11px;
  margin-right: 8px;
  @media (max-width: 420px) {
    margin-right: 3px;
  }
  @media (max-width: 420px) {
    margin-right: 5px;
  }
`;

const OrderCheckboxBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: black;
  height: 22px;
  width: 22px;
  background-color: white;
  @media (max-width: 420px) {
    height: 17px;
    width: 17px;
  }
`;

// const OrderName = styled.input`
//   height: 32px;
//   width: 100%;
//   background-color: white;
//   font-size: ${pxIntoRem(11)};
//   font-family: "Roboto", sans-serif;
//   font-weight: 500;
//   letter-spacing: -0.1px;
//   color: black;
//   padding: 0px 13px;
// margin-top: 14px;
//   @media (max-width: 420px) {
//     height: 27px;
//     padding: 0px 6.5px;
//     margin-top: 7px;
//   }
// `;

export { OrderInputs, OrderTheme, OrderCheckboxes, OrderCheckbox, OrderCheckboxText, OrderCheckboxBox };
