import React, { FC, PropsWithChildren } from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import { DefaultBlock, DefaultBody } from "./DefaultStyles";

const Default: FC<PropsWithChildren> = ({ children }) => {
  return (
    <DefaultBlock>
      <Container>
        <DefaultBody>
          <Header />
          {children}
        </DefaultBody>
      </Container>
    </DefaultBlock>
  );
};

export default Default;
