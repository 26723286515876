import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const OrderBlock = styled.div`
  width: 100%;
  height: 100%;
  padding: 42px 0px 15px 0px;
  @media (max-width: 420px) {
    padding: 21px 0px 7.5px 0px;
  }
`;

const OrderTitle = styled.h1`
  font-size: ${pxIntoRem(18)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: ${pxIntoRem(22)};
  color: #ff7656;
`;

const OrderBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 15px;
  padding: 27px 18px 16px 20px;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1b5b65;
    opacity: 0.95;
    z-index: -1;
  }

  @media (max-width: 420px) {
    padding: 13.5px 9px 10px 10px;
    margin-top: 7.5px;
  }
`;

const OrderText = styled.p`
  font-size: ${pxIntoRem(13.5)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: white;
  letter-spacing: 0.15px;
  line-height: ${pxIntoRem(21)};
  padding: 0px 5px 0px 13px;
  @media (max-width: 420px) {
    padding: 0px 2.5px 0px 6.5px;
  }
`;

const OrderButton = styled.button`
  margin-top: 16px;
  height: 32px;
  width: 100%;
  background-color: #ff7656;
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: white;
  cursor: pointer;
  opacity: 1;
  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 420px) {
    height: 27px;
    margin-top: 8px;
  }
`;

export { OrderBlock, OrderTitle, OrderBody, OrderText, OrderButton };
