import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const OrderMessageBlock = styled.textarea`
  margin-top: 9px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 21.5vh;
  background-color: white;
  font-size: ${pxIntoRem(11)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: black;
  padding: 23px 13px;
  letter-spacing: -0.1px;
  @media (max-width: 420px) {
    padding: 11.5px 6.5px;
  }
`;

export { OrderMessageBlock };
