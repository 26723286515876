import { FC } from "react";
import { OrderMessageBlock } from "./OrderMessageStyles";

interface IOrderMessage {
  value: string;
  onChange(value: string): any;
}
const OrderMessage: FC<IOrderMessage> = ({ value, onChange }) => {
  return <OrderMessageBlock placeholder="Текст вашего сообщения" value={value} onChange={(e) => onChange(e.currentTarget.value)} />;
};

export default OrderMessage;
