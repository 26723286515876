import React, { FC, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../utils/telegram/backButton";
import Default from "../Default/Default";
import { OrderBlock, OrderBody, OrderTitle, OrderText, OrderButton } from "./OrderStyles";

interface IOrder {
  title: string;
  text: string;
  onButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  isButtonDisabled: boolean;
}

const Order: FC<PropsWithChildren<IOrder>> = ({ children, title, text, onButtonClick, isButtonDisabled }) => {
  const navigate = useNavigate();

  useEffect(() => {
    BackButton.onClick(() => {
      navigate(-1);
    });
    BackButton.show();

    return () => {
      BackButton.offClick();
      BackButton.hide();
    };
  }, []);

  return (
    <Default>
      <OrderBlock>
        <OrderTitle>{title}</OrderTitle>
        <OrderBody>
          <OrderText>{text}</OrderText>
          {children}
          <OrderButton onClick={onButtonClick} disabled={isButtonDisabled}>
            ОТПРАВИТЬ СООБЩЕНИЕ
          </OrderButton>
        </OrderBody>
      </OrderBlock>
    </Default>
  );
};

export default Order;
