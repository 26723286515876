class WebApp {
  static expand() {
    window.Telegram.WebApp.expand();
  }

  static ready() {
    window.Telegram.WebApp.ready();
  }

  static sendData(data: string) {
    window.Telegram.WebApp.sendData(data);
  }

  static sendJsonData(data: object) {
    window.Telegram.WebApp.sendData(JSON.stringify(data));
  }

  static openLink(url: string) {
    window.Telegram.WebApp.openLink(url);
  }

  static openTelegramLink(url: string) {
    window.Telegram.WebApp.openTelegramLink(url);
  }
}

export default WebApp;
