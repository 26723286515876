import styled from "styled-components";

const DefaultBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const DefaultBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export { DefaultBlock, DefaultBody };
