import React, { Children, FC, PropsWithChildren, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import WebApp from "../../utils/telegram/webapp";
import {
  HeaderBlock,
  HeaderBurgerBlock,
  HeaderBurgerButton,
  HeaderBurgerLine,
  HeaderBurgerMenu,
  HeaderBurgerMenuButton,
  HeaderBurgerMenuCloseBlock,
  HeaderBurgerMenuCloseButton,
  HeaderBurgerMenuCloseLine,
  HeaderLogo,
  HeaderLogoBlock,
} from "./HeaderStyles";

const Header: FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  function openHeader() {
    setIsOpen(true);
  }

  function closeHeader() {
    setIsOpen(false);
  }

  function redirectToIndex() {
    navigate("/");
  }

  function redirectToCustom() {
    navigate("/order/custom");
  }

  function redirectToIntermediary() {
    navigate("/order/intermediary");
  }

  function redirectToConsult() {
    navigate("/order/consult");
  }

  function redirectToSite() {
    WebApp.openLink("https://tgapps.ru/");
  }

  return (
    <HeaderBlock>
      <HeaderBurgerBlock>
        {isOpen && (
          <HeaderBurgerMenu onClick={closeHeader}>
            <HeaderBurgerMenuCloseBlock>
              <HeaderBurgerMenuCloseButton>
                <HeaderBurgerMenuCloseLine />
                <HeaderBurgerMenuCloseLine />
              </HeaderBurgerMenuCloseButton>
            </HeaderBurgerMenuCloseBlock>
            <HeaderBurgerMenuButton backgroundColor="#015b65" onClick={redirectToSite}>
              Перейти на сайт проекта
            </HeaderBurgerMenuButton>
            <HeaderBurgerMenuButton backgroundColor="#015b65" onClick={redirectToConsult}>
              Требуется консультация
            </HeaderBurgerMenuButton>
            <HeaderBurgerMenuButton backgroundColor="#ff7656" onClick={redirectToCustom}>
              Заказать разработку
            </HeaderBurgerMenuButton>
            <HeaderBurgerMenuButton backgroundColor="#ff7656" onClick={redirectToIntermediary}>
              Как зарабатывать вместе с нами?
            </HeaderBurgerMenuButton>
          </HeaderBurgerMenu>
        )}
        <HeaderBurgerButton onClick={openHeader}>
          <HeaderBurgerLine />
          <HeaderBurgerLine />
          <HeaderBurgerLine />
        </HeaderBurgerButton>
      </HeaderBurgerBlock>
      <HeaderLogoBlock>
        <HeaderLogo alt="logo" src="/images/logo.png" onClick={redirectToIndex} />
      </HeaderLogoBlock>
    </HeaderBlock>
  );
};

export default Header;
