class BackButton {
  private static callback: Function;

  static show() {
    window.Telegram.WebApp.BackButton.show();
  }

  static hide() {
    window.Telegram.WebApp.BackButton.hide();
  }

  static onClick(callback: Function) {
    BackButton.offClick();
    BackButton.callback = callback;
    Telegram.WebApp.onEvent("backButtonClicked", BackButton.callback);
  }

  static offClick() {
    Telegram.WebApp.offEvent("backButtonClicked", BackButton.callback);
  }
}

export default BackButton;
