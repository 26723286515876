import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const ProductBlock = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding-bottom: 15px;
`;

const ProductPrice = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
`;

const ProductPriceNet = styled.h2`
  font-size: ${pxIntoRem(22)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -1.15px;
  line-height: ${pxIntoRem(22)};
  color: #e2a89b;
  text-transform: uppercase;
  span {
    color: #1b5b65;
    text-transform: lowercase;
  }
`;

const ProductPriceNotNet = styled.h3`
  font-size: ${pxIntoRem(16)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -1.15px;
  line-height: ${pxIntoRem(42)};
  color: #e2a89b;
  text-transform: uppercase;
  letter-spacing: -0.4px;
  span {
    color: #1b5b65;
    text-transform: lowercase;
  }
`;

const ProductInfo = styled.div`
  width: 100%;
  background-color: #3d737c;
  padding: 6px 30px 27px;
  @media (max-width: 470px) {
    padding: 6px 15px 13.5px;
  }
`;

const ProductInfoTitle = styled.h2`
  font-size: ${pxIntoRem(20)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${pxIntoRem(42)};
  color: #ffffff;

  span {
    color: #ffb3a1;
  }
`;

const ProductInfoDescription = styled.p`
  font-size: ${pxIntoRem(14)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: ${pxIntoRem(21.5)};
  color: #ffffff;
  margin-top: 5px;
`;

const ProductImageBlock = styled.div`
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  padding-bottom: 50px;

  @media (max-width: 370px) {
    padding-bottom: 120px;
  }
`;

const ProductImage = styled.img`
  max-width: 380px;
  min-height: 290px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 370px) {
    max-width: 300px;
  }
`;

const ProductOptions = styled.div`
  position: absolute;
  right: 0;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  //   align-items: flex-end;
  padding: 22px 35px 20px 20px;
  max-width: 317px;

  & * {
    z-index: 2;
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.9;
    z-index: 1;
  }

  @media (max-width: 370px) {
    padding: 11px 17.5px 10px 10px;
  }
`;

const ProductOptionsTitle = styled.h3`
  font-size: ${pxIntoRem(18)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.8px;
  line-height: ${pxIntoRem(36)};
  color: #e2a89b;
  text-transform: uppercase;

  @media (max-width: 370px) {
    letter-spacing: -0.1px;
  }
`;

const ProductOptionsList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  padding-left: 22px;
`;

const ProductOption = styled.li`
  font-size: ${pxIntoRem(15)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.7px;
  color: #494d4e;
  line-height: ${pxIntoRem(21)};
  @media (max-width: 370px) {
    letter-spacing: -0.5px;
  }
`;

const ProductAddOptions = styled.div`
  position: relative;
  width: 100%;
  padding: 26px 40px 38px 40px;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.95;
    z-index: 1;
  }

  & * {
    z-index: 2;
  }

  @media (max-width: 370px) {
    padding: 13px 20px 19px 20px;
  }
`;

const ProductAddOptionsTitle = styled.h2`
  font-size: ${pxIntoRem(17)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.8px;
  line-height: ${pxIntoRem(36)};
  color: #e2a89b;
  text-transform: uppercase;
  position: relative;
  @media (max-width: 370px) {
    letter-spacing: -0.1px;
  }
`;

const ProductAddOptionsList = styled.ul`
  position: relative;
  list-style: none;
  margin-top: 14px;
  @media (max-width: 420px) {
    margin-top: 7px;
  }
`;

const ProductAddOption = styled.div`
  position: relative;
  display: block;
  margin-top: 20px;
  cursor: pointer;
  &:first-of-type {
    margin-top: 0px;
  }
  @media (max-width: 420px) {
    margin-top: 5px;
  }
`;

interface IProductAddOptionCheckbox {
  isChecked: boolean;
}

const ProductAddOptionCheckbox = styled.div<IProductAddOptionCheckbox>`
  width: 13px;
  height: 13.5px;
  ${({ isChecked }) => !isChecked && "border: 2px solid #696969;"}
  background-color: ${({ isChecked }) => (isChecked ? "#0c8f83" : "transparent")};
  position: absolute;
  left: 3px;
  top: 1px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${pxIntoRem(10)};
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: white;
  @media (max-width: 420px) {
    width: 10px;
    height: 10.5px;
  }
`;

const ProductAddOptionText = styled.p`
  font-size: ${pxIntoRem(14)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: ${pxIntoRem(21.5)};
  color: #6e878e;
  padding-left: 47px;
  @media (max-width: 370px) {
    padding-left: 23.5px;
  }
`;

const ProductTotalPrice = styled.h2`
  font-size: ${pxIntoRem(18)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: -0.8px;
  line-height: ${pxIntoRem(22)};
  color: #e2a89b;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  padding: 20px 0px 22px;
  span {
    color: #1b5b65;
    text-transform: lowercase;
    letter-spacing: 0px;
  }

  @media (max-width: 370px) {
    letter-spacing: -0.1px;
  }
`;

const ProductOrderButton = styled.button`
  height: 32px;
  width: 100%;
  background-color: #ff7656;
  font-size: ${pxIntoRem(14)};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: white;
  cursor: pointer;
  z-index: 2;
  position: relative;
  @media (max-width: 370px) {
    height: 27px;
  }
`;

export {
  ProductBlock,
  ProductPrice,
  ProductPriceNet,
  ProductPriceNotNet,
  ProductInfo,
  ProductInfoTitle,
  ProductInfoDescription,
  ProductImageBlock,
  ProductImage,
  ProductOptions,
  ProductOptionsTitle,
  ProductOptionsList,
  ProductOption,
  ProductAddOptions,
  ProductAddOptionsTitle,
  ProductAddOptionsList,
  ProductAddOption,
  ProductAddOptionText,
  ProductAddOptionCheckbox,
  ProductTotalPrice,
  ProductOrderButton,
};
